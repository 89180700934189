  //  const baseURL = 'http://192.168.2.3:9091/';
// 测试
 // const baseURL = 'https://ky.docteams.cn/testdoctor/';
 // const dominUrlvform = 'https://ky.docteams.cn/devform';
 // const dominPatient = 'https://ky.docteams.cn/devpatient/';
 // const dominDoctor =  'https://ky.docteams.cn/devdoctor/';
//正式
const baseURL = 'https://ky.docteams.cn/apidoctor';

 const dominPatient = 'https://ky.docteams.cn/patient/';
 const dominDoctor =  'https://ky.docteams.cn/doctor/';
 const dominUrlvform = 'https://ky.docteams.cn/vform';
// 测试跳转表单配置

// 正式跳转表单配置
 const imgUrl = 'https://aibiboat.oss-cn-shanghai.aliyuncs.com/'; //鉴权图片预览地址
const dominUrl = "https://ky.docteams.cn";

const dominUrl2 = 'https://ky.docteams.cn/doctor/';
const downloadUrl = "https://aibiboat.oss-cn-shanghai.aliyuncs.com/";
export default {
  baseURL,
  imgUrl,
  dominPatient,
  dominDoctor,
  dominUrlvform,
  dominUrl,

  dominUrl2,
  downloadUrl
}